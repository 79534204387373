import { twMerge } from "tailwind-merge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";

const Button = ({
  size = "large",
  children,
  hoverChildren,
  className,
  hoverClassName,
  disabled = false,
  loading = false,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <button
      className={twMerge(
        "flex h-12 items-center rounded-sm bg-primary600 px-4 font-avara text-base font-black text-white",
        size === "small" && "h-9 px-3 text-sm",
        size === "extraLarge" && "h-[52px] text-base",
        (disabled || loading) && "cursor-not-allowed opacity-30",
        className,
        hovered && hoverClassName
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
      onClick={!disabled && !loading ? onClick : undefined}
    >
      {loading ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className={twMerge(
            "text-2xl text-white",
            size === "small" && "text-base"
          )}
          spin
        />
      ) : hovered && hoverChildren ? (
        hoverChildren
      ) : (
        children
      )}
    </button>
  );
};

export default Button;

import { store } from "store";
import { REDUCER_STARKNET_WALLET } from "./reducers/reducerStarknetWallet";

const fixStarknetAddress = (address) => {
  // Ensure the address starts with '0x' and pad with zeros to 66 characters
  return address.startsWith("0x")
    ? `0x${address.slice(2).padStart(64, "0")}`
    : address;
};

export const setStarknetWalletAddress = (payload) => {
  store.dispatch({
    type: REDUCER_STARKNET_WALLET.SET_STARKNET_ADDRESS,
    data: fixStarknetAddress(payload),
  });
};

export const setStarknetWalletDomain = (payload) => {
  store.dispatch({
    type: REDUCER_STARKNET_WALLET.SET_STARKNET_DOMAIN,
    data: payload,
  });
};

export const setStarknetWalletMobileUnsupportedWarning = (payload) => {
  store.dispatch({
    type: REDUCER_STARKNET_WALLET.SET_STARKNET_MOBILE_UNSUPPORTED_WARNING,
    data: payload,
  });
};

export const clearStarknetWalletData = (payload) => {
  store.dispatch({
    type: REDUCER_STARKNET_WALLET.CLEAR_STARKNET_DATA,
  });
};

import { WagmiConfig } from "wagmi";
import { mainnet } from "viem/chains";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Root from "views/Root";
import Main from "views/Main";

import {ToastComponent} from "components/ToastComponent";

// Setup Wallet Connect
const projectId = "ddafe69173054236a347430b41250fbd"; // Get projectId at https://cloud.walletconnect.com
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const chains = [mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata }); // Create wagmiConfig
createWeb3Modal({ wagmiConfig, projectId, chains }); // Initialize web3 modal

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [{ path: "/", element: <Main /> }],
  },
]);

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RouterProvider router={router} />
        <ToastComponent />
      </WagmiConfig>
    </>
  );
}

export default App;

import axios from "axios";

const API_HOST = process.env.REACT_APP_API_HOST;

export const getEthereumWhitelist = async (ethereumAddress) => {
  return axios.get(`${API_HOST}/launchpad/api/wallet/whitelist`, {
    params: {
      ethereumAddress,
    },
  });
};

export const getBindingStatus = async (ethereumAddress, starkNetAddress) => {
  return axios.get(`${API_HOST}/launchpad/api/wallet/binding`, {
    params: {
      ethereumAddress,
      starkNetAddress,
    },
  });
};

export const putBindingAccounts = async (ethereumAddress, starkNetAddress) => {
  return axios.put(`${API_HOST}/launchpad/api/wallet/binding`, {
    ethereumAddress,
    starkNetAddress,
  });
};

export const putUnbindingAccounts = async (ethereumAddress) => {
  return axios.put(`${API_HOST}/launchpad/api/wallet/unbinding`, {
    ethereumAddress,
  });
};

export const getGrugSmasehedStatus = async (walletAddress) => {
  return axios.get(`${API_HOST}/whitelist`, {
    params: {
      walletAddress,
    },
  });
};

export const verifyGrugSmashedSignature = async (walletAddress, signatureArr) => {
  return axios.get(`${API_HOST}/whitelist/verify`, {
    headers: {
      'x-signature': JSON.stringify([walletAddress, ...signatureArr])
    }
  });
};

export const postGrugSmashed = async (walletAddress, signatureArr) => {
  return axios.post(`${API_HOST}/whitelist`, {
    walletAddress,
  }, {
    headers: {
      'x-signature': JSON.stringify([walletAddress, ...signatureArr])
    }
  });
};

export const resetGrugSmashed = async (walletAddress) => {
  return axios.delete(`${API_HOST}/whitelist`, {
    data: {
      walletAddress,
    },
  });
};

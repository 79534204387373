import React, { useEffect, useState } from "react";
import LocomotiveScroll from "locomotive-scroll";

import { scaleToRange } from "utils";

import MainLoading from "components/MainLoading";

import Introduction from "./Introduction";
import Games from "./Games";
import Roadmap from "./Roadmap";
import TheTeam from "./TheTeam";
import BeOneOfUs from "./BeOneOfUs";
import Footer from "./Footer";

const Main = () => {
  const [resizeCount, setResizeCount] = useState(0);

  // const handleMobileScroll = (direction) => {
  //   if (window.innerWidth < 768 && !window.stopLocomotiveMobileScroll) {
  //     const isUp = direction === "up" || direction === null;
  //     let scrollTarget = null;
  //     const scrollOffsets = getScrollOffset();
  //     switch (window.mobileCurrentSection) {
  //       // Introduction
  //       case 0:
  //         scrollTarget = isUp ? null : scrollOffsets.gamesIntro;
  //         break;
  //       // Rising Revenant Intro
  //       case 1:
  //         scrollTarget = isUp
  //           ? scrollOffsets.introduction
  //           : scrollOffsets.risingRevenant;
  //         break;
  //       // Rising Revenant
  //       case 2:
  //         scrollTarget = isUp
  //           ? scrollOffsets.gamesIntro
  //           : scrollOffsets.roadmap;
  //         break;
  //       default:
  //     }
  //     if (scrollTarget) {
  //       window.stopLocomotiveMobileScroll = true;
  //       window.mobileCurrentSection += isUp ? -1 : 1;
  //       scrollLocomotive(scrollTarget, {
  //         duration: 1000,
  //         disableLerp: true,
  //         callback: () =>
  //           setTimeout(() => (window.stopLocomotiveMobileScroll = false), 100),
  //       });
  //     }
  //   }
  // };

  const finishLoading = () => {
    if (!window.isProcessingFinishLoading) {
      window.isProcessingFinishLoading = true;
      const mainLoading = document.getElementById("main-loading");
      mainLoading?.classList.remove("opacity-0");
      mainLoading?.classList.remove("hidden");
      document
        .getElementById("introduction-title-background")
        ?.classList.remove("visible");

      // Fade out loading screen
      setTimeout(() => {
        mainLoading?.classList.add("opacity-0");
        window.setMarqueeStart?.(true);
        window.locomotive?.update?.();
        window.startGrugSmashed?.();
        // Hide loading screen and reinitiate locomotive scroll
        setTimeout(() => {
          mainLoading?.classList.add("hidden");
          window.locomotive.canNavigate = true;
          window.locomotive?.start?.();

          // Animate Pioneering background
          setTimeout(() => {
            window.isProcessingFinishLoading = false;
            document
              .getElementById("introduction-title-background")
              ?.classList.add("visible");
          }, 100);
        }, 1100);
      }, 1000);
    }
  };

  useEffect(() => {
    window.mobileCurrentSection = 0;

    // Update scroll on load complete
    document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        window.finishedLoading = true;
        finishLoading();
      }
    });

    setTimeout(() => {
      if (!window.finishedLoading) {
        window.finishedLoading = true;
        finishLoading();
      }
    }, 3000);

    // Add resize handler
    const handleResize = () => {
      setResizeCount((prev) => prev + 1);
      finishLoading();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const introduction = document.getElementById("introduction");
    const mouseScrollIndicator = document.getElementById(
      "mouse-scroll-indicator"
    );
    const gamesIntro = document.getElementById("games-intro");

    const blobArenaContent = document.getElementById("blob-arena-content");
    const blobArenaMainOverlay = document.getElementById(
      "blob-arena-main-overlay"
    );
    const blobArenaSword = document.getElementById("blob-arena-sword");
    const blobArenaShield = document.getElementById("blob-arena-shield");

    const risingRevenantContent = document.getElementById(
      "rising-revenant-content"
    );
    const risingRevenantMainOverlay = document.getElementById(
      "rising-revenant-main-overlay"
    );
    const roadmapTitle = document.getElementById("roadmap-title");
    const roadmapScroller = document.getElementById("roadmap-scroller");

    gamesIntro && (gamesIntro.style.opacity = 1);
    roadmapTitle && (roadmapTitle.style.marginTop = "50vh");
    roadmapScroller && (roadmapScroller.style.marginLeft = 0);

    const locomotive = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      getDirection: true,
      multiplier: window.innerWidth <= 500 ? 2 : 1,
      tablet: {
        smooth: true,
      },
      smartphone: {
        smooth: true,
      },
    });
    locomotive.stop();
    window.locomotive = locomotive;

    locomotive.on("scroll", (event) => {
      // handleMobileScroll(event.direction);
      const games = event.currentElements["games"];
      const roadmap = event.currentElements["roadmap"];
      if (games) {
        const progressInVh = games.progress * 750;

        introduction.style.opacity = progressInVh < 187.5 ? 1 : 0;
        mouseScrollIndicator.style.opacity = progressInVh < 56.25 ? 1 : 0;

        const initialWidth =
          window.innerWidth >= 1024
            ? window.innerWidth * 0.8
            : window.innerWidth - 32;

        // Expand black card to full width
        games.el.style.width = `${
          scaleToRange(progressInVh, 0, 75) *
            (window.innerWidth - initialWidth) +
          initialWidth
        }px`;

        // Fades out games intro text
        gamesIntro.style.opacity = 1 - scaleToRange(progressInVh, 93.75, 112.5);
        gamesIntro.style.display = progressInVh <= 112.5 ? "flex" : "none";

        // Render blob arena contents
        blobArenaContent.style.display =
          progressInVh >= 90 && progressInVh <= 519.75 ? "block" : "none";
        // Animates blob fighting sword
        let blobOffset = progressInVh > 221.25 ? 35 : 10;
        let blobRotate = 0;
        if (progressInVh >= 135 && progressInVh <= 210) {
          blobOffset = 100 - scaleToRange(progressInVh, 135, 210, 90, 100);
        } else if (progressInVh >= 210 && progressInVh <= 285) {
          blobOffset = scaleToRange(progressInVh, 210, 285, 0, 35);
        }
        if (progressInVh >= 187.5 && progressInVh <= 210) {
          blobRotate = scaleToRange(progressInVh, 187.5, 210, 0, 20);
        } else if (progressInVh >= 210 && progressInVh <= 232.5) {
          blobRotate = 100 - scaleToRange(progressInVh, 210, 232.5, 80, 100);
        }
        blobArenaSword.style.marginRight = `${blobOffset}%`;
        blobArenaShield.style.marginLeft = `${blobOffset}%`;
        blobArenaSword.getElementsByTagName(
          "img"
        )[0].style.transform = `rotate(${blobRotate}deg)`;
        blobArenaShield.getElementsByTagName(
          "img"
        )[0].style.transform = `rotate(${blobRotate}deg)`;
        // Fades in blob arena contents
        blobArenaMainOverlay.style.opacity = scaleToRange(
          progressInVh,
          296.25,
          333.75
        );
        // Enable blob arena touch events
        blobArenaMainOverlay.style.pointerEvents =
          progressInVh >= 296.25 && progressInVh <= 453.75 ? "all" : "none";
        // Fades out blob arena contents
        blobArenaContent.style.opacity =
          1 - scaleToRange(progressInVh, 378.75, 453.75);
        // Stop blob arena amma audio if scrolled
        if (progressInVh <= 93.75 || progressInVh >= 453.75) {
          window?.stopAmmaAudio?.();
        }

        // Render rising revenant contents
        risingRevenantContent.style.display =
          progressInVh >= 378.75 && progressInVh <= 637.5 ? "block" : "none";
        // Fades in rising revenant contents
        risingRevenantMainOverlay.style.opacity = scaleToRange(
          progressInVh,
          470,
          507.5
        );
        
        games.el.style.opacity = 1 - scaleToRange(progressInVh, 562.5, 637.5);
      }

      if (roadmap) {
        const cards = document.getElementsByClassName("the-team-cards");
        const cardRange = scaleToRange(roadmap.progress, 0, 0.15, 0, 120);
        Array.from(cards).forEach((card, i) => {
          const percentage = Math.max(
            120 - cardRange - (cards.length - i) * 10,
            0
          );
          card.style.transform = `translateY(${percentage}%)`;
        });
        roadmapTitle.style.marginTop = `${
          50 - scaleToRange(roadmap.progress, 0.15, 0.7, 0, 100)
        }%`;
        roadmapTitle.style.opacity =
          1 - scaleToRange(roadmap.progress, 0.15, 0.7);
        roadmapScroller.style.marginLeft = `-${scaleToRange(
          roadmap.progress,
          0.15,
          0.7,
          0,
          roadmapScroller.scrollWidth - window.innerWidth
        )}px`;
      }
    });

    return () => {
      locomotive.destroy();
    };
  }, [resizeCount]);

  return (
    <>
      <MainLoading />
      <div
        data-scroll-container
        className="flex flex-col items-center overflow-hidden"
      >
        <Introduction />
        <Games />
        <Roadmap />
        <TheTeam />
        <BeOneOfUs />
        <Footer />
      </div>
    </>
  );
};

export default Main;

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  connect as connectWallet,
  disconnect as disconnectWallet,
} from "starknetkit";
import axios from "axios";

import {
  clearStarknetWalletData,
  setStarknetWalletAddress,
  setStarknetWalletDomain,
} from "store/actions";

const useArgentWallet = () => {
  const { address, domain } = useSelector((state) => state.reducerStarknetWallet);
  const starknet = useRef(null);

  const connect = async () => {
    const connection = await connectWallet();
    if (connection?.wallet?.isConnected) {
      setStarknetWalletAddress(connection.wallet.selectedAddress);
      starknet.current = connection;
    }
    return connection;
  };

  const disconnect = async () => {
    await disconnectWallet();
    clearStarknetWalletData();
    starknet.current = null;
  };

  const fetchStarkName = async (address) => {
    try {
      const { data } = await axios.get(
        `https://api.starknet.id/addr_to_domain?addr=${address}`
      );
      setStarknetWalletDomain(data.domain);
    } catch {
      setStarknetWalletDomain(null);
    }
  };

  // Reinitialize starknet object when already connected
  useEffect(() => {
    if (address) {
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        try {
          const connection = await connectWallet({ modalMode: "neverAsk" });
          if (connection?.wallet?.isConnected) {
            setStarknetWalletAddress(connection.wallet.selectedAddress);
            starknet.current = connection;
            fetchStarkName(connection.wallet.selectedAddress);
            connection.wallet.on("accountsChanged", () => {
              if (starknet.current) {
                setStarknetWalletAddress(connection.wallet.selectedAddress);
                fetchStarkName(connection.wallet.selectedAddress);
              }
            })
          } else {
            clearStarknetWalletData();
          }
        } catch (e) {
          clearStarknetWalletData();
        }
      })();
    }
  }, [address]);

  return { connect, disconnect, address, domain, starknet: starknet.current };
};

export default useArgentWallet;

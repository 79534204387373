export const REDUCER_STARKNET_WALLET = {
  SET_STARKNET_ADDRESS: "SET_STARKNET_ADDRESS",
  SET_STARKNET_DOMAIN: "SET_STARKNET_DOMAIN",
  SET_STARKNET_MOBILE_UNSUPPORTED_WARNING: "SET_STARKNET_MOBILE_UNSUPPORTED_WARNING",
  CLEAR_STARKNET_DATA: "CLEAR_STARKNET_DATA",
};

const initialState = {
  address: null,
  domain: null,
  mobileUnsupportedWarning: false,
};

const reducerStarknetWallet = (state = initialState, { type, data }) => {
  switch (type) {
    case REDUCER_STARKNET_WALLET.SET_STARKNET_ADDRESS:
      return { ...state, address: data };
    case REDUCER_STARKNET_WALLET.SET_STARKNET_DOMAIN:
      return { ...state, domain: data };
    case REDUCER_STARKNET_WALLET.SET_STARKNET_MOBILE_UNSUPPORTED_WARNING:
      return { ...state, mobileUnsupportedWarning: data };
    case REDUCER_STARKNET_WALLET.CLEAR_STARKNET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducerStarknetWallet;

const MainLoading = () => {
  return (
    <div
      id="main-loading"
      className="absolute z-20 flex h-screen w-screen items-center justify-center bg-grugBlack transition-opacity duration-1000"
    >
      <div className="animate-pulse">
        <img
          src={require("assets/images/grugsLairLong.png")}
          className="w-[300px] object-contain"
          alt="grugs-lair"
        />
      </div>
    </div>
  );
};

export default MainLoading;

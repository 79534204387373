import {
  faDiscord,
  faXTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import BlobArenaLogo from "assets/images/blob-arena/blob-arena-logo.png";
import RisingRevenantLogo from "assets/images/rising-revenant/rising-revenant-long-logo.png";

import { openLink, scrollLocomotive } from "utils";

export const getScrollOffset = () => ({
  introduction: "top",
  risingRevenantIntro: window.innerHeight * 0.95,
  blobArena: window.innerHeight * 4,
  risingRevenant: window.innerHeight * 6,
  roadmap: window.innerHeight * (window.innerWidth >= 1024 ? 7.8 : 8),
  ourTeam: document.getElementById("the-team")?.offsetTop - 100,
});

export const HEADER_MENUS = [
  {
    title: "Blob Arena",
    onClick: () =>
      window.locomotive?.canNavigate &&
      scrollLocomotive(getScrollOffset().blobArena, undefined, 2),
    hover_logo: BlobArenaLogo,
  },
  {
    title: "Rising Revenant",
    onClick: () =>
      window.locomotive?.canNavigate &&
      scrollLocomotive(getScrollOffset().risingRevenant, undefined, 3),
    hover_logo: RisingRevenantLogo,
  },
  {
    title: "Roadmap",
    onClick: () =>
      window.locomotive?.canNavigate &&
      scrollLocomotive(getScrollOffset().roadmap, undefined, 4),
  },
  {
    title: "Our Team",
    onClick: () =>
      window.locomotive?.canNavigate &&
      scrollLocomotive(getScrollOffset().ourTeam, { offset: 0 }, 5),
  },
];

export const FOOTER_MENUS = [
  {
    title: "Our Allegory",
    onClick: () =>
      openLink(
        "https://medium.com/@grugslair/the-allegory-of-grug-9259254990e6"
      ),
  },
  {
    title: "Blob Arena",
    onClick: () => openLink("https://realms.world/games/blob-arena"),
    hover_logo: BlobArenaLogo,
  },
  {
    title: "Rising Revenant",
    onClick: () => openLink("https://realms.world/games/rising-revenant"),
    hover_logo: RisingRevenantLogo,
  },
  // {
  //   title: "Contact Us",
  //   onClick: () => window.location.href = "mailto:info@grugslair.com",
  // },
];

export const SOCIAL_MEDIAS = [
  { logo: faDiscord, link: "https://discord.gg/gDyJBYUNDq" },
  { logo: faXTwitter, link: "https://x.com/GrugsLair" },
  { logo: faGithub, link: "https://github.com/grugslair" },
];

import { isChrome, isIOS, isSafari } from "react-device-detect";

export const ellipseAddress = (address = "", width = 5) => {
  const normalizedWidth = width >= 2 ? width : 2;
  if (!address) {
    return "";
  }
  return `${address.slice(0, normalizedWidth)}...${address.slice(
    -(normalizedWidth - 1)
  )}`;
};

export const objectToBase64 = (object) => {
  try {
    var objectString = JSON.stringify(object);
    return btoa(objectString);
  } catch (error) {
    console.log(error);
    return "";
  }
};
export const base64ToObject = (string) => {
  try {
    var objectString = atob(string);
    return JSON.parse(objectString);
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const scaleToRange = (value, oldMin, oldMax, newMin = 0, newMax = 1) => {
  // Calculate the scaling factor
  var scalingFactor = (newMax - newMin) / (oldMax - oldMin);
  // Scale the value to the new range
  var scaledValue = (value - oldMin) * scalingFactor + newMin;
  return Math.min(Math.max(scaledValue, newMin), newMax);
};

export const scrollLocomotive = (
  target,
  options = {},
  mobileScrollSection = null
) => {
  if (mobileScrollSection !== null) {
    window.stopLocomotiveMobileScroll = true;
  }
  window.locomotive.scrollTo(target, {
    offset: isIOS && (isChrome || isSafari) && target !== "top" ? 200 : 0,
    duration: 2000,
    disableLerp: window.innerWidth < 768,
    callback: () => {
      if (mobileScrollSection !== null) {
        setTimeout(() => (window.stopLocomotiveMobileScroll = false), 100);
        window.mobileCurrentSection = mobileScrollSection;
      }
    },
    ...options,
  });
};

export const openLink = (link) => {
  window.open(link, window.innerWidth >= 728 ? "_blank" : "_self");
};

export const withMinWait = async (apiCall, minWaitTime) => {
  const start = Date.now();
  const response = await apiCall();
  const duration = Date.now() - start;
  const waitTime = Math.max(minWaitTime - duration, 0);

  if (waitTime > 0) {
    await new Promise(resolve => setTimeout(resolve, waitTime));
  }

  return response;
}

export const getNextUTCDate = () => {
  const now = new Date();
  now.setUTCDate(now.getUTCDate() + 1);
  now.setUTCHours(0, 0, 0, 0);
  return now;
};

/* global BigInt */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Countdown from "react-countdown";
import toast from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

// Utils
import {
  getGrugSmasehedStatus,
  postGrugSmashed,
  resetGrugSmashed,
  verifyGrugSmashedSignature,
} from "request";
import { ellipseAddress, getNextUTCDate, withMinWait } from "utils";
import useArgentWallet from "hooks/useArgentWallet";
import useMediaQuery from "hooks/useMediaQuery";

// Components
import Button from "components/Button";
import CenterLoading from "components/CenterLoading";
import ShiningStar from "./ShiningStar";

// Assets
import ImageHammerSVG from "assets/images/grug-smashed/hammer.svg";
import ImageFloating from "assets/images/grug-smashed/floating-entry.png";
import ImageSmash1 from "assets/images/grug-smashed/smash-1.png";
import ImageSmash2 from "assets/images/grug-smashed/smash-2.png";
import ImageSmash3 from "assets/images/grug-smashed/smash-3.png";
import ImageSmashBroken from "assets/images/grug-smashed/smash-broken.png";
import ImageSmashResult from "assets/images/grug-smashed/smash-result.png";
import AudioRockHit from "assets/audio/rock-hit.mp3";
import AudioRockShatter from "assets/audio/rock-shatter2.mp3";

import style from "./style.module.scss";

const GrugSmashed = () => {
  const audioRockHit = new Audio(AudioRockHit);
  const audioRockShatter = new Audio(AudioRockShatter);

  const shiningStarRef = useRef();
  const lastSmashTime = useRef(new Date());
  const utcTimerRef = useRef();
  const connectWalletOnDemandRef = useRef(false);
  const [data, setData] = useState({});
  const [opened, setOpened] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smashCount, setSmashCount] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  const [signature, setSignature] = useState();

  const { connect, disconnect, address, domain, starknet } = useArgentWallet();
  const isSmallWidth = useMediaQuery("(max-width: 1280px)");
  const isSmallHeight = useMediaQuery("(max-height: 800px)");
  const isSmallScreen = isSmallWidth || isSmallHeight;
  const isMobile = useMediaQuery("(max-width: 834px)");

  useEffect(() => {
    window.startGrugSmashed = startGrugSmashed;
  }, []);

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        setMounted(true);
      }, 100); // Making sure element is mounted before animating
    }
  }, [opened]);

  useLayoutEffect(() => {
    if (address && opened) {
      (async () => {
        const result = await fetchGrugSmashedStatus();
        !result.openedToday &&
          starknet?.wallet?.id !== "argentMobile" &&
          validateSignature();
      })();
    } else {
      destroyFetchAtUTC();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, opened]);

  const fetchGrugSmashedStatus = async () => {
    setLoading(true);
    let returnData = {};
    try {
      const response = await getGrugSmasehedStatus(address);
      setData(response.data);
      returnData = response.data;
    } catch (e) {
      toast.error("Something went wrong. Please try again.");
      setData({
        openedToday: true,
        whitelisted: false,
      });
      returnData = {
        openedToday: true,
        whitelisted: false,
      };
    }
    setSignature();
    setSmashCount(0);
    setLoading(false);
    initializeFetchAtUTC();
    return returnData;
  };

  const submitGrugSmashed = async () => {
    let result = {};
    await withMinWait(async () => {
      try {
        const response = await postGrugSmashed(address, signature);
        result = response.data;
      } catch (e) {
        toast.error("Something went wrong. Please try again.");
        result = {
          openedToday: true,
          whitelisted: false,
        };
      }
    }, 3000); // Animation duration for ShiningStar to cover full screen
    setData(result);
    shiningStarRef.current?.stop();
  };

  const startGrugSmashed = () => {
    setTimeout(() => {
      if (!localStorage.getItem("grugSmashedFirstPopup")) {
        setOpened(true);
        localStorage.setItem("grugSmashedFirstPopup", true);
      }
    }, 2000); // Delay 2 seconds after main screen load complete before showing the popup
  };

  const initializeFetchAtUTC = () => {
    // Refetch every day at UTC
    const msUntilUTC = getNextUTCDate() - Date.now();
    utcTimerRef.current = setTimeout(fetchGrugSmashedStatus, msUntilUTC);
  };

  const destroyFetchAtUTC = () => {
    clearTimeout(utcTimerRef.current);
  };

  const validateSignature = async () => {
    if (signature) return signature;

    const chainId = await starknet?.wallet?.provider?.getChainId();
    if (chainId !== "0x534e5f4d41494e") {
      toast.error("Please connect to Mainnet in order to play.");
      return null;
    }

    const messageStructure = {
      types: {
        StarkNetDomain: [
          { name: "name", type: "felt" },
          { name: "chainId", type: "felt" },
          { name: "version", type: "felt" },
        ],
        Message: [{ name: "message", type: "felt" }],
      },
      primaryType: "Message",
      domain: {
        name: "Grug's Lair",
        chainId: "0x534e5f4d41494e",
        version: "0.0.1",
      },
      message: {
        message: "Sign to verify your ownership",
      },
    };

    try {
      setLoading(true);
      const result = await starknet.wallet.account.signMessage(
        messageStructure,
        { skipDeploy: true }
      );
      const signatureResult = result.map((e) => BigInt(e).toString(16));
      const verifyResult = await verifyGrugSmashedSignature(
        address,
        signatureResult
      );
      if (!verifyResult.data) {
        toast.error(
          "Your account isn't deployed yet. Complete the deployment by adding ETH to your account. You can play after the deployment is complete.",
          {
            duration: 10000,
          }
        );
        return null;
      }
      setSignature(signatureResult);
      return result;
    } catch (e) {
      disconnect();
      setSignature(null);
      setData({});
      return null;
    } finally {
      setLoading(false);
    }
  };

  const onClickSmash = async () => {
    if (new Date() - lastSmashTime.current < 700) return; // Prevent spamming
    if (!address) {
      connectWalletOnDemandRef.current = true;
      connect();
    } else {
      if (!signature) return validateSignature();
      lastSmashTime.current = new Date();
      if (smashCount === 2) {
        handleFinalSmash();
      } else {
        shakeRock();
      }
      setSmashCount((c) => c + 1);
    }
  };

  const handleFinalSmash = () => {
    shakeRock(true);
    setTimeout(() => {
      shiningStarRef.current?.start();
    }, 200);
    submitGrugSmashed();
  };

  const shakeRock = (hardShake = false) => {
    const audio = hardShake ? audioRockShatter : audioRockHit;
    audio.play();
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), hardShake ? 3000 : 500); // Hard shake lasts until ShiningStar covers full screen
  };

  const onBeforeClose = () => {
    setMounted(false);
    setTimeout(() => setOpened(false), 500); // Making sure element is animated before unmounting
  };

  const getDisplayData = () => {
    if (!address) {
      return {
        canSmash: true,
        image: ImageSmash1,
        title: "An unknown rock has appeared",
        subtitle:
          "Smash it and you might get a spot on exclusive Grug NFT Whitelist",
      };
    }
    if (data.whitelisted && data.openedToday) {
      return {
        canSmash: false,
        image: ImageSmashResult,
        title: "You got a spot on Grug NFT Whitelist!",
        subtitle: "Use the badge to redeem the NFT when it's live",
      };
    }
    if (data.whitelisted && !data.openedToday) {
      return {
        canSmash: false,
        image: ImageSmashResult,
        title: "You have been whitelisted!",
        subtitle: "Use the badge to redeem the NFT when it's live",
      };
    }
    if (!data.whitelisted && data.openedToday) {
      return {
        canSmash: false,
        image: ImageSmashBroken,
        title: "The rock has shattered to pieces",
        subtitle: (
          <>
            You've got nothing from it. The next one will appear in{" "}
            <Countdown date={getNextUTCDate()} daysInHours />
          </>
        ),
      };
    }
    if (!data.whitelisted && !data.openedToday) {
      return {
        canSmash: true,
        image: getSmashImage(),
        title: "Smash the rock by clicking it",
        subtitle: "You need several clicks to reveal what's inside",
      };
    }
  };

  const getSmashImage = () => {
    switch (smashCount) {
      case 1:
        return ImageSmash2;
      case 2:
      case 3:
        return ImageSmash3;
      default:
        return ImageSmash1;
    }
  };

  const displayData = getDisplayData();

  return (
    <>
      {isSmallScreen ? (
        <img
          src={ImageFloating}
          className={twMerge(
            style.grugSmashedFloatingEntry,
            "fixed bottom-10 right-2 h-[100px]"
          )}
          draggable="false"
          alt="floating-grug-smashed"
          onClick={() => setOpened(true)}
        />
      ) : (
        <Button
          size="small"
          className="absolute -left-[208px] -top-[2px] w-[196px] justify-end border border-primary600 bg-transparent pr-[14px] font-sora font-normal"
          onClick={() => setOpened(true)}
        >
          <img
            src={ImageSmash1}
            alt="button-rock"
            draggable="false"
            className="absolute -left-[28px] -top-[28px] h-[80px]"
          />
          GET NFT WHITELIST
        </Button>
      )}

      {opened && (
        <div
          className={twMerge(
            "fixed left-0 top-0 z-[11] flex h-full w-full items-center justify-center transition-opacity duration-500",
            !mounted && "opacity-0"
          )}
        >
          <div className="absolute h-full w-full bg-grugBlack bg-opacity-90" />
          <div className="z-[1] box-content flex w-full max-w-[565px] flex-col items-center justify-center px-4">
            <div
              className={twMerge(
                "relative mb-4 h-[480px] w-[480px]",
                isSmallScreen && "h-[300px] w-[300px]"
              )}
            >
              <div
                className={twMerge(
                  isShaking && style.grugSmashedImageShake,
                  "h-full w-full"
                )}
              >
                <img
                  src={displayData.image}
                  alt="smash"
                  className={twMerge(
                    style.grugSmashedImage,
                    "h-full w-full object-contain"
                  )}
                />
                <div
                  className={twMerge(
                    displayData.canSmash && style.grugSmashedImageCanSmash,
                    "absolute left-1/2 top-[52%] h-[220px] w-[160px] -translate-x-1/2 -translate-y-1/2 bg-opacity-35",
                    "tablet:top-[55%] tablet:h-[340px] tablet:w-[240px]"
                  )}
                  onMouseDown={() => displayData.canSmash && onClickSmash()}
                />
              </div>
              <FontAwesomeIcon
                icon={faTimes}
                className={twMerge(
                  "absolute right-0 top-0 cursor-pointer text-3xl text-white tablet:-right-[48px] tablet:text-5xl",
                  !data.openedToday && "hidden"
                )}
                onClick={onBeforeClose}
              />
              <ShiningStar ref={shiningStarRef} />
            </div>
            <h1 className="text-center font-avara text-3xl font-extrabold text-white tablet:text-5xl">
              {displayData.title}
            </h1>
            <p className="mt-4 max-w-[448px] text-center font-sora text-lg font-light text-white tablet:text-2xl">
              {displayData.subtitle}
            </p>
            <div className="mt-10 flex gap-4">
              {address ? (
                <>
                  <Button
                    className="cursor-default border border-white bg-[#0B0B0B33]"
                    size={isMobile ? "small" : "large"}
                  >
                    ID: {domain || ellipseAddress(address)}
                  </Button>
                  <Button
                    onClick={() => {
                      onBeforeClose();
                      setTimeout(() => {
                        disconnect();
                        setSignature(null);
                        setData({});
                      }, 1000);
                    }}
                    className="bg-error500"
                    size={isMobile ? "small" : "large"}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="-mt-[4px] mr-2 text-base tablet:text-lg"
                    />
                    Disconnect
                  </Button>
                  {/* <Button
                    onClick={async () => {
                      await resetGrugSmashed(address);
                      onBeforeClose();
                    }}
                    className="absolute bottom-0 right-0 bg-error500"
                    size={isMobile ? "small" : "large"}
                  >
                    Reset
                  </Button> */}
                </>
              ) : (
                <>
                  <Button
                    className="border border-white bg-[#0B0B0B33]"
                    onClick={onBeforeClose}
                    size={isMobile ? "small" : "large"}
                  >
                    Do it later
                  </Button>
                  <Button
                    onClick={() => {
                      connectWalletOnDemandRef.current = true;
                      connect();
                    }}
                    size={isMobile ? "small" : "large"}
                  >
                    <img
                      src={ImageHammerSVG}
                      alt="hammer"
                      layout="fill"
                      className="-mt-[6px] mr-2 h-4 tablet:h-5"
                    />
                    Smash it now
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="fixed left-0 top-0 z-[12] h-full w-full">
          <div className="absolute h-full w-full bg-grugBlack bg-opacity-30" />
          <CenterLoading />
        </div>
      )}
    </>
  );
};

export default GrugSmashed;

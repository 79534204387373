import React from "react";

import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import style from "./style.module.scss";

const CenterLoading = ({className}) => {
  return (
    <div className={`${style.container} ${className}`}>
      <FontAwesomeIcon icon={faSpinner} className={style.icon} spin />
    </div>
  );
};

export default CenterLoading;

import { Outlet } from "react-router-dom";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faMobileAlt } from "@fortawesome/pro-solid-svg-icons";

import { setStarknetWalletMobileUnsupportedWarning } from "store/actions";

import Header from "components/Header";

import tailwindConfig from "tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";

const tailwind = resolveConfig(tailwindConfig);

function Root() {
  const { mobileUnsupportedWarning } = useSelector(
    (state) => state.reducerStarknetWallet
  );

  return (
    <>
      <Header />
      <Outlet />
      <ReactModal
        isOpen={mobileUnsupportedWarning}
        style={{
          content: {
            padding: "32px 16px",
            height: "fit-content",
            margin: "auto",
            backgroundColor: tailwind.theme.colors.grugCardBackground,
            border: `1px solid ${tailwind.theme.colors.grugBorder}`,
            borderRadius: 0,
          },
          overlay: {
            zIndex: 20,
            backgroundColor: "rgba(0,0,0,.45)",
          },
        }}
        shouldCloseOnOverlayClick
        onRequestClose={() => setStarknetWalletMobileUnsupportedWarning(false)}
      >
        <p className="text-center font-avara text-sm font-black text-white">
          <span className="fa-layers h-12 w-12">
            <FontAwesomeIcon icon={faMobileAlt} className="text-2xl" />
            <FontAwesomeIcon icon={faBan} className="text-5xl opacity-70" />
          </span>
          <br />
          <br />
          StarkWallet Connect is not currently supported on Mobile.
          <br />
          <br />
          Please use Desktop instead
        </p>
      </ReactModal>
    </>
  );
}

export default Root;

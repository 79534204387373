import React from "react";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import {openLink} from "utils";

import style from "./style.module.scss";

const BeOneOfUs = () => {
  return (
    <div
      data-scroll-section
      className={twMerge(
        "mt-20 flex w-full max-w-[912px] flex-col items-center pb-[120px]",
        "smallDesktop:mt-[120px] smallDesktop:pb-[240px]"
      )}
    >
      <h1
        className={twMerge(
          "px-4 text-center font-avara text-4xl font-extrabold text-white",
          "smallDesktop:px-0 smallDesktop:text-6xl"
        )}
      >
        Help us&nbsp;
        <span className="relative overflow-hidden whitespace-nowrap">
          <div
            data-scroll
            className={twMerge(
              style.be_one_of_us_title,
              "absolute -left-1 -top-0 h-full w-full",
              "tablet:-left-2"
            )}
          >
            <div
              className="absolute h-full w-[105%] bg-primary600"
              style={{
                transform:
                  "perspective(12px) rotate(2deg) rotateX(-0.8deg) rotateY(-0.2deg)",
              }}
            />
          </div>
          <span className="relative">shape</span>
        </span>
        &nbsp;the future of Onchain Gaming
      </h1>
      <Button
        className="mt-14"
        onClick={() => openLink("https://discord.gg/gDyJBYUNDq")}
      >
        Join The Lair
      </Button>
    </div>
  );
};

export default BeOneOfUs;

import React from "react";

import style from "./style.module.scss";
import { twMerge } from "tailwind-merge";

const ROADMAPS = [
  {
    quarter: "Q4 2023",
    cards: [
      {
        emoji: "⛓️",
        text: "Grug's Lair transition to Full Onchain Gaming Studio",
      },
      {
        image: require("assets/icons/frontinus-house.jpg"),
        text: "Entry into Frontinus House Round 1",
      },
      {
        image: require("assets/icons/alpha-rising-revenant.jpg"),
        text: "Alpha Release of Rising Revenant",
      },
    ],
  },
  {
    quarter: "Q1 2024",
    cards: [
      {
        emoji: "📜",
        text: "Incorporation of Grug's Lair",
      },
      {
        emoji: "🎮",
        text: "Rising Revenant Playtests",
      },
    ],
  },
  {
    quarter: "Q2 2024",
    cards: [
      {
        image: require("assets/images/realms-world.png"),
        text: "Studio Alignment with Bibliotheca DAO",
      },
    ],
  },
  {
    quarter: "Q3 2024",
    cards: [
      {
        emoji: "📣",
        text: "New Game Announcement",
      },
    ],
  },
  {
    quarter: "Q4 2024",
    cards: [
      {
        image: require("assets/icons/blob-arena.png"),
        text: "Blob Arena Mainnet Release",
      },
      {
        image: require("assets/icons/rising-revenant.png"),
        text: "Rising Revenant Mainnet Release",
      },
    ],
  },
  {
    quarter: "2025",
    cards: [
      {
        emoji: '🎮',
        text: "New Game Mainnet Release",
      },
      {
        image: require("assets/icons/starknet-nft.jpg"),
        text: "Starknet NFT Genesis Collection",
      },
    ],
  },
];

const Card = ({ emoji, image, text }) => (
  <div
    className={twMerge(
      "the-team-cards h-[362px] w-[242px] rounded-sm border border-grugBorder bg-grugCardBackground p-8",
      "smallDesktop:h-[452px] smallDesktop:w-[332px] smallDesktop:translate-y-[120%] smallDesktop:p-10"
    )}
  >
    <div className="size-12 text-5xl leading-none smallDesktop:size-[72px] smallDesktop:text-7xl smallDesktop:leading-none pointer-events-none">
      {image ? <img src={image} alt="icon" className="size-full object-contain" /> : emoji}
    </div>
    <h2
      className={twMerge(
        "mt-14 font-avara text-xl font-bold text-white",
        "smallDesktop:mt-20 smallDesktop:text-3xl"
      )}
    >
      {text}
    </h2>
  </div>
);

const Roadmap = () => {
  const horizontalPadding = Math.max((window.innerWidth - 1440) / 2 + 148, 148);
  const isMobile = window.innerWidth < 1024;
  return !isMobile ? (
    <div
      data-scroll
      data-scroll-section
      data-scroll-id="roadmap"
      data-scroll-offset="50%"
      id="roadmap-container"
      className="-mt-[50vh] min-h-[300vh] w-full"
    >
      <div
        data-scroll
        data-scroll-sticky
        data-scroll-target="#roadmap-container"
        className="relative h-screen w-full"
      >
        <h1
          id="roadmap-title"
          className={twMerge(
            style.text_vertical,
            "absolute font-avara text-[210px] font-extrabold text-white"
          )}
          style={{ left: horizontalPadding }}
        >
          Roadmap
        </h1>
        <div
          id="roadmap-scroller"
          className="flex h-full w-fit items-center gap-10"
          style={{ padding: `0 ${horizontalPadding}px` }}
        >
          <div className="w-[458px]" />
          {ROADMAPS.map(({ quarter, cards }, i) => (
            <div key={quarter} className="z-[1] flex flex-col gap-10">
              <div className="flex items-center gap-10">
                <h2 className="font-avara text-5xl font-extrabold text-primary500">
                  {quarter}
                </h2>
                {i !== ROADMAPS.length - 1 && (
                  <div className="-mt-2 h-[2px] flex-1 overflow-hidden opacity-40">
                    <div className={style.horizontal_roadmap_border} />
                  </div>
                )}
              </div>
              <div className="flex gap-10">
                {cards.map((card, j) => (
                  <Card key={`${i}_${j}`} {...card} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      data-scroll
      data-scroll-section
      id="roadmap-container"
      className="-mt-[70vh] w-full"
    >
      <div
        data-scroll
        data-scroll-sticky
        data-scroll-target="#roadmap-container"
        className="absolute h-screen w-full"
      >
        <h1
          className={twMerge(
            style.text_vertical,
            "absolute left-[50%] -ml-24 mt-[80px] -translate-x-1/2 rotate-180 font-avara text-[100px] font-extrabold text-white opacity-5"
          )}
        >
          Roadmap
        </h1>
      </div>
      <div className="mt-40 flex flex-col items-center gap-10">
        {ROADMAPS.map(({ quarter, cards }, i) => (
          <div key={quarter} className="z-[1] flex gap-4">
            <div className="flex flex-col items-center gap-8">
              <h2
                className={twMerge(
                  style.text_vertical,
                  "font-avara text-3xl font-extrabold text-primary500"
                )}
              >
                {quarter}
              </h2>
              {i !== ROADMAPS.length - 1 && (
                <div className="-ml-2 w-[2px] flex-1 overflow-hidden opacity-40">
                  <div className={style.vertical_roadmap_border} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4">
              {cards.map((card, j) => (
                <Card key={`${i}_${j}`} {...card} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;

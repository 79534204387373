import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { twMerge } from "tailwind-merge";

// Assets
import AudioGlitterIn from "assets/audio/glitter-in.mp3";
import AudioGlitterOut from "assets/audio/glitter-out.mp3";

import style from "./style.module.scss";

const ShiningStar = forwardRef((_, ref) => {
  const audioGlitterIn = new Audio(AudioGlitterIn);
  const audioGlitterOut = new Audio(AudioGlitterOut);

  const [show, setShow] = useState(false);
  const [mounted, setMounted] = useState(true);

  // Expose methods to parent via ref
  useImperativeHandle(ref, () => ({
    start: () => {
      audioGlitterIn.play();
      setShow(true);
      setTimeout(() => {
        audioGlitterOut.play();
      }, 1500);
    },
    stop: () => {
      setTimeout(() => {
        setShow(false);
      }, 500);
    },
  }));

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setMounted(false);
      }, 2000);
    } else {
      setMounted(true);
    }
  }, [show]);

  return (
    mounted && (
      <div
        className={twMerge(
          style.shiningStar,
          "absolute left-0 top-0 h-full w-full",
          !show && "opacity-0"
        )}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          overflow="visible"
        >
          <g
            className="group"
            style={{ filter: "drop-shadow(0 0 10px yellow)" }}
          >
            <g className="large">
              <path
                id="large"
                d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
                fill="white"
              />
            </g>
            <g className="large-2" transform="rotate(45)">
              <use xlinkHref="#large" />
            </g>
            <g className="small">
              <path
                id="small"
                d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75 L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      </div>
    )
  );
});

export default ShiningStar;

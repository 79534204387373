import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";

// Components
import Button from "components/Button";

const ImageGallery = ({ visible, onClose = () => null, images = [] }) => {
  const locked = useRef(false);
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(0);
  const [displayed, setDisplayed] = useState(0);

  const onClickNext = () => {
    updateSelection((selected + 1) % images.length);
  };

  const onClickPrev = () => {
    updateSelection(selected - 1 === -1 ? images.length - 1 : selected - 1);
  };

  const updateSelection = (next) => {
    if (!locked.current) {
      locked.current = true;
      setSelected(-1);
      setTimeout(() => {
        setSelected(next);
        setDisplayed(next);
        locked.current = false;
      }, 500);
      const images = document.getElementsByClassName("gallery-image");
      images?.[next]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const onBeforeClose = () => {
    setMounted(false);
    setTimeout(onClose, 500);
  };

  useEffect(() => {
    if (visible) {
      setSelected(0);
      setDisplayed(0);
      setMounted(true);
    }
  }, [visible]);

  return (
    visible && (
      <div
        className={twMerge(
          "absolute z-[11] flex h-full w-full items-center justify-center transition-opacity duration-500",
          !mounted && "opacity-0"
        )}
      >
        <div
          className="absolute h-full w-full bg-grugBlack bg-opacity-90"
          onClick={onBeforeClose}
        />
        <div
          className={twMerge(
            "z-[1] flex w-full max-w-[928px] flex-col px-4",
            "smallDesktop:px-0"
          )}
        >
          <Button
            onClick={onBeforeClose}
            className={twMerge(
              "mb-4 mr-8 self-end border border-primary600 bg-transparent text-primary600",
              "smallDesktop:mb-6 smallDesktop:mr-16"
            )}
            size={window.innerWidth >= 1024 ? "large" : "small"}
          >
            <FontAwesomeIcon icon={faTimes} className="-mt-1 mr-2 text-lg" />
            Close
          </Button>
          <div
            className={twMerge("flex items-center gap-2", "smallDesktop:gap-6")}
          >
            <div
              className={twMerge(
                "flex size-6 cursor-pointer items-center justify-center",
                "smallDesktop:size-10"
              )}
              onClick={onClickPrev}
            >
              <FontAwesomeIcon
                icon={faCircleChevronLeft}
                className={twMerge(
                  "text-xl text-white",
                  "smallDesktop:text-4xl"
                )}
              />
            </div>
            <img
              className={twMerge(
                "aspect-video flex-1 overflow-hidden object-cover transition-opacity duration-500",
                selected === -1 && "opacity-0"
              )}
              src={images[displayed]}
              alt="gallery"
            />
            <div
              className={twMerge(
                "flex size-6 cursor-pointer items-center justify-center",
                "smallDesktop:size-10"
              )}
              onClick={onClickNext}
            >
              <FontAwesomeIcon
                icon={faCircleChevronRight}
                className={twMerge(
                  "text-xl text-white",
                  "smallDesktop:text-4xl"
                )}
              />
            </div>
          </div>
          {window.innerWidth >= 1024 && (
            <div className="mx-16 mt-8 flex gap-4 overflow-x-auto rounded-sm border border-grugBorder bg-grugCardBackground p-4">
              {images.map((image, i) => (
                <div
                  key={i}
                  className={twMerge(
                    "gallery-image aspect-video w-[240px] flex-none cursor-pointer overflow-hidden rounded-sm border border-transparent transition-colors duration-500",
                    i === selected && "border-primary600"
                  )}
                  onClick={(e) => {
                    updateSelection(i);
                  }}
                >
                  <img
                    src={image}
                    alt="thumbnail"
                    className="h-full w-full object-cover"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ImageGallery;

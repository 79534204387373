import React from "react";
import { twMerge } from "tailwind-merge";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";

export const ToastComponent = () => {
  return (
    <Toaster position="top-right">
      {(t) => (
        <ToastBar
          toast={t}
          position="top-right"
          style={{ padding: 0, borderRadius: 8 }}
        >
          {() => {
            let bgClass, faIcon;
            switch (t.type) {
              case "success":
                bgClass = "bg-success700";
                faIcon = faCheckCircle;
                break;
              case "error":
                bgClass = "bg-error700";
                faIcon = faExclamationCircle;
                break;
              default:
            }
            return (
              <div
                className={twMerge(
                  "flex min-w-80 gap-2 rounded-lg bg-white p-4 text-white",
                  bgClass
                )}
              >
                <div className="flex size-8 items-center justify-center">
                  <FontAwesomeIcon icon={faIcon} className="text-xl" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <div className="font-sora text-base">{t.title}</div>
                  {!!t.message && (
                    <div className="mt-1 font-sora text-sm font-light">
                      {t.message}
                    </div>
                  )}
                </div>
                <div
                  className="flex size-5 cursor-pointer items-center justify-center"
                  onClick={() => toast.dismiss(t.id)}
                >
                  <FontAwesomeIcon icon={faXmark} className="text-lg" />
                </div>
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};
